html {
  scroll-behavior: smooth;
}
.body {
  display: flex;
}

.resume {
  margin-top: 1em;
  margin-bottom: 2rem;
  background-color: #000;
  padding: 5px;
  text-align: center;
  border: 5px solid #fff;
  filter: drop-shadow(0px 2px 2px #fff);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.resume:hover {
  transform: translateY(-10px);
  border: 5px solid #fff;
  filter: drop-shadow(0px 7px 7px #fff);
}

.resume:active {
  transition: all 0.1s;
  transform: translateY(-5px);
  border: 5px solid #fff;
  filter: drop-shadow(0px 9px 9px #fff);
  color: #fff;
}

.tools {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  /* align-items: center; */
  justify-content: space-around;
}

.tools h2 {
  /* margin-top: 10%; */
  margin-bottom: 5%;
  text-align: center;
  font-family: "Press Start 2P", cursive;
}
.tools li {
  margin: 20px;
  text-align: center;
  font-family: "DotGothic16", sans-serif;
  letter-spacing: 2px;
}
.skillz {
  width: 50%;
}
@media (max-width: 768px) {
  .skillz {
    width: 100%;
    margin: 50px 0;
  }
}

.hobbiz {
  /* margin-top: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.skillset {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}
.ulSkills {
  /* margin-top: 10px; */
  /* padding: 0px 50px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 10px; */
  column-gap: 55px;
}
.ulHobbies {
  margin-top: 10px;
  padding: 0px 50px;
  gap: 5px;
  display: flex;
  flex-direction: column;
}
/* .skillset li {
  display: flex;
  justify-content: space-between;
} */
.skillImg img {
  width: 30px;
  height: auto;
  margin: 1px;
  /* filter: grayscale(100%); */
  float: right;
  /* filter: invert(100%); */
}
.ulHobbies {
  cursor: default;
}
.ulHobbies img {
  filter: invert(100%);
  width: 25px;
  height: auto;
  margin: 1px;
}

.guts img {
  width: 100%;
  height: auto;
}
#aboutMe {
  position: relative;
  left: 0px;
  top: -90px;
  /* Adjust this */
}

/* .skillElement:hover {
  border-bottom: #fff dashed 0.5px;
  border-radius: 5%;

} */

.skillElement {
  gap: 28px;
}

.skillName {
  padding-right: 10px;
  white-space: nowrap;
  transition: all 0.1 !important;
}
