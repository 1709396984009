h2 {
  font-size: 40px;
}

.wonderOfYou {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  padding-bottom: 40px;
}

.workTitle {
  /* position: relative; */
  /* top: -30px; */
  font-family: "Press Start 2P", cursive;
  /* border: #000 solid 6px; */
  color: #000 !important;
  /* background-color: #fff; */
}

.dictionary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: px;
  /* grid-auto-rows: minmax(100px, auto); */
  text-align: center;
}

.preview img {
  width: 100%;
  height: auto;
}

.contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.ask {
  margin: 40px 40px 40px 40px;
  text-align: center;
  font-size: 20px;
  font-family: "Press Start 2P", cursive;
  line-height: 35px;
}

.ask h2,
.ask h1 {
  color: #000;
  margin-top: 10px;
}

.emailButton {
  /* margin: 1%; */
  background-color: #fff;
  color: #000;
  padding: 2%;
  margin-top: 15px;
  /* width: auto;
        height: auto; */
  text-align: center;
  border: 5px solid #000;
  border-radius: 4px;
  font-family: "Press Start 2P", cursive;
  filter: drop-shadow(0px 5px 5px #000);
  cursor: pointer;
}
.emailButton a {
  color: #000;
  /* font-size: 19px; */
}

/* .emailButton a {
  color: #000;
} */
.emailButton:hover {
  transform: translateY(-15px);
  border: 5px solid #000;
  filter: drop-shadow(0px 10px 10px #000);
}

.emailButton:active {
  transition: all 0.1s;
  transform: translateY(-5px);
  border: 5px solid #fff;
  filter: drop-shadow(0px 10px 5px #fff);
  background-color: #fff;
  color: #fff;
}

#work {
  position: relative;
  left: 0px;
  top: -90px;
}

@media (max-width: 400px) {
  .emailButton {
    font-size: 10px;
  }
}
@media (max-width: 1200px) {
  .dictionary {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 900px) {
  .dictionary {
    grid-template-columns: repeat(1, 1fr);
  }
}
