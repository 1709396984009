@media (max-width: 600px) {
    .footer {
        /* background-size: contain; */
            /* background-repeat: no-repeat; */
            height: max-content;
    }

    .socials {
        display: flex;
        /* margin-left: 0; */
    }

    #soc img {
        width: 50%;
        height: auto;
        cursor: pointer;
        margin-bottom: 85px;
    }

    #soc {
        width: 15%;

    }
}