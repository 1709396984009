@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  /* color: #fff; */
  text-decoration: none;
  transition: all 0.5s;
}

body {
  background-color: #000;
  color: #fff;
}
::selection {
  background-color: #fff;
  color: #000;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 24px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.24);
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #2e2e2e;
}
