@media (max-width: 600px) {
  .body {
    display: flex;
    flex-direction: column;
  }

  .me {
    width: 100%;
    margin: 10px;
    margin-left: 0;
  }

  .me h2,
  .me h1 {
    margin: 0 15px;
  }

  .me h1 {
    font-size: 25px;
  }

  .me h2 {
    font-size: 19px;
    margin-top: 10px;
  }

  .me p {
    margin: 15px 15px;
    font-size: 14px;
  }

  .tools {
    flex-direction: column;
    width: 100%;
  }

  .tools h2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .ulSkills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .skillz {
    width: 100%;
  }
  .resume {
    max-width: 100%;
    font-size: 50px;
    padding: 10px 50px;
    margin: auto;
  }

  .dictionary {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .ask h2 {
    font-size: 25px;
  }

  .ask h1 {
    font-size: 30px;
  }

  .emailButton {
    margin: auto;
    padding: 0;
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  /* .body {
    flex-direction: column;
  } */
  .rightRes {
    display: none;
  }
}
@media (max-width: 1000px) {
  .tools {
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
}
