.me {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  margin-top: 2%;
  margin-left: 3%;
  /* width: 100%; */
}
.me h1 {
  font-size: 40px;
  font-family: "Press Start 2P", cursive;
  letter-spacing: 0px;
}
.me h2 {
  font-family: "Press Start 2P", cursive;
  /* font-family: 'DotGothic16', sans-serif; */
  font-size: 20px;
  letter-spacing: 1px;
  margin-top: 5px;
}
.me p {
  margin-top: 30px;
  width: 90%;
  line-height: 21px;
  text-indent: 45px;
  font-family: "Major Mono Display", monospace;
}

.resume {
  /* margin-top: 1em;
  margin-bottom: 2rem;
  background-color: #000;
  padding: 5px;
  text-align: center;
  border: 5px solid #fff;
  filter: drop-shadow(0px 2px 2px #fff);
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-size: 30px; */
}
.myInfo h3 {
  /* margin-top: 10%; */
  text-align: center;
  font-family: "Press Start 2P", cursive;
  width: 40%;
  margin-left: 5%;
  padding: 10px 40px;
  /* margin-left: 5%; */
}
.myInfo {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

@media (min-width: 1900px) {
  .me h1 {
    font-size: 50px;
  }
  .me p {
    font-size: 20px;
    line-height: 25px;
  }
  .rightRes {
    display: none;
  }
  .leftRes {
    width: 50%;
  }
}

@media (min-width: 2350px) {
  .me h1 {
    font-size: 60px;
  }
  .me p {
    font-size: 25px;
    line-height: 30px;
  }
  .rightRes {
    display: none;
  }
}

@media (min-width: 600px) {
  .rightRes {
    display: none;
  }
}

@media (max-width: 600px) {
  .leftRes {
    display: none;
  }
  .rightRes {
    display: block;
  }
}
