.preview img {
  /* border-radius: 4px; */
  /* filter: drop-shadow(0px 4px 5px #fff); */
}

.preview img:hover {
  transition: all 0.1s;
  transform: translateY(-5px);
  filter: drop-shadow(0px 8px 8px #848383);
}

.preview img:active {
  transition: all 0.1s;
  transform: translateY(-1px);
  filter: drop-shadow(0px 8px 8px #fff);
}

.anchorP {
  display: flex;
  flex-direction: column;

  /* width: 25%; */
  /* padding: 50px; */
  margin: 40px;
  /* height: 70%; */
  overflow: hidden;
  background-color: #171717;
  text-align: center;
  border-radius: 24px;
  filter: drop-shadow(0px 4px 6px #000);
}

/* .term {
  display: flex;
  flex-direction: column;
  padding: 50px;
  margin: 40px;
  height: auto;
  overflow: hidden;
  background-color: #171717;
  text-align: center;
  border-radius: 24px;
  filter: drop-shadow(0px 4px 6px #000);
} */

.title {
  margin-top: 20px;
  font-size: 24px;
  font-family: "Press Start 2P", cursive;
  text-align: center;
  line-height: 30px;
}

.link {
  margin-top: 10px;
  font-family: "Major Mono Display", monospace;
  line-height: 25px;
}

@media (max-width: 400px) {
  .title {
    font-size: 15px;
  }
  .link {
    font-size: 11px;
  }
}

.box {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 400px;
}
.box img {
  position: relative;
  width: 100%;
  height: 100%;
  transform: perspective(1000px) rotateX(0deg);
  transition: 0.9s;
  backface-visibility: hidden;
}
.box:hover img {
  transform: perspective(1000px) rotateX(-180deg);
}
.box .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #505050 0% 50%, #323232 50% 100%);
  color: #fff;
  transform: perspective(1000px) rotateX(180deg);
  transition: 0.9s;
  backface-visibility: hidden;
}
.box:hover .content {
  transform: perspective(1000px) rotateX(0deg);
}
.box .content .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  text-align: center;
}
.box .content h1 {
  font-size: 20px;
}
.box .content h1 span {
  font-size: 16px;
  color: #c1c1c1;
}
.box .content ul {
  position: relative;
  margin-top: 10px;
}
.box .content ul li {
  list-style: none;
  display: inline-block;
}
.box .content ul li a {
  position: relative;
  display: inline-block;
  color: #fff;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  transition: 0.5s;
}
.box .content ul li a:hover {
  background: #9c27b0;
}
