#soc {
  filter: invert(100%);
  /* position: relative; */
  /* top: 100px; */
  /* height: 0px; */
  filter: drop-shadow(0px 2px 5px #fff);
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}

#soc:hover {
  transition: all 0.1s;
  transform: translateY(-5px);
  filter: drop-shadow(0px 4px 6px #fff);
}

#soc:active {
  transition: all 0.1s;
  transform: translateY(-1px);
  filter: drop-shadow(0px 8px 8px #fff);
}

#soc img {
  width: 4%;
  height: auto;
  cursor: pointer;
}

.socials {
  margin-left: 5%;
  padding-top: 5%;
  /* padding-bottom: 15%; */
}

.footer {
  /* background-image: 'url(./media/y.jpg)'; */
  background-size: cover;
  height: 550px;
}
